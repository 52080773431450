import "./App.css";
import Navbar from "./Components/Navbar/Navbar";
import SplashPage from "./Components/SplashPage/SplashPage";
import AboutMe from "./Components/AboutMe/AboutMe";
import Projects from "./Components/Projects/Projects";
import Footer from "./Components/Footer/Footer";

function App() {
  return (
    <div class="container">
      <Navbar />
      <SplashPage />
      <AboutMe />
      <Projects />
      <Footer />
    </div>
  );
}

export default App;
