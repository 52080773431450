import React from "react";
import Project from "./Project/Project";
import "./ProjectsStyles.css";
import projects from "./projects.json";
import CrawlerIO from "./Project/projectImages/crawlerio.png";
import DoorLoopBlog from "./Project/projectImages/doorloop_blog.png";
import ExcellentStore from "./Project/projectImages/excellent_store.png";
import JSONFileMgr from "./Project/projectImages/json_file_manager.png";
import TypingTest from "./Project/projectImages/typing_test.png";
import Calculators from "./Project/projectImages/calculators.jpg";
import Chatbot from "./Project/projectImages/real-estate-ai.png";
import GreenTree from "./Project/projectImages/greentree.png";
import Quizzes from "./Project/projectImages/custom-quiz.png";

const Projects = () => {
  let projectInfo = projects.projects;
  return (
    <div class="projects-container">
      <Project info={projectInfo[0]} img={GreenTree} />
      <Project info={projectInfo[1]} img={Chatbot} />
      <Project info={projectInfo[2]} img={Quizzes} />
      <Project info={projectInfo[3]} img={Calculators} />
      <Project info={projectInfo[4]} img={CrawlerIO} />
      <Project info={projectInfo[5]} img={JSONFileMgr} />
      <Project info={projectInfo[6]} img={TypingTest} />
      <Project info={projectInfo[7]} img={ExcellentStore} />
    </div>
  );
};

export default Projects;
