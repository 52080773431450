import React from "react";
import Card from "./Card/Card";
import "./AboutMeStyles.css";
import cardInfo from "./aboutMeInfo.json";
import MarketingIcon from "./Card/images/marketing_specialist_icon.svg";
import SEIcon from "./Card/images/software_engineer_icon.svg";
import WriterIcon from "./Card/images/content_writer_icon.svg";
import { useInView } from "react-intersection-observer";

const AboutMe = () => {
  let cards = cardInfo.cards;

  const { ref, inView } = useInView({
    triggerOnce: true,
    rootMargin: "-250px 0px",
  });

  return (
    <div ref={ref} class={`${inView ? "aboutme-container" : "hidden"}`}>
      <h1 class="aboutme-heading">About Me</h1>
      <h2 className="aboutme-subheading">
        Operations manager specializing in software development,
        <br />
        marketing technologies & automations with modern technologies.
      </h2>
      <div className="aboutme-cards-container">
        <Card info={cards[0]} image={MarketingIcon} />
        <Card info={cards[1]} image={SEIcon} />
        <Card info={cards[2]} image={WriterIcon} />
      </div>
      <div className="aboutme-learn-more-container">
        <div className="aboutme-learn-more-content">
          <div className="aboutme-learn-more-heading">
            <h1 className="aboutme-learn-more-heading-text">
              Why Not Learn More?
            </h1>
          </div>
          <div className="aboutme-learn-more-heading">
            <a
              className="aboutme-download-resume-button"
              href="https://santiagoaday.com/Santiago_Aday_Resume.pdf"
              target="_blank"
              rel="noreferrer"
            >
              <h1 className="aboutme-download-resume-button-text">
                Download My Resume
              </h1>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutMe;
