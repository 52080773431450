import React from "react";
import "./NavbarStyles.css";
import SantiLogo from "../../Resources/santi_logo.png";
import { ReactComponent as Linkedin } from "../../Resources/linkedin.svg";
import { ReactComponent as Github } from "../../Resources/github.svg";
import { ReactComponent as Resume } from "../../Resources/resume.svg";

const Navbar = () => {
  return (
    <div class="navbar-container">
      <div class="logo-container">
        <img src={SantiLogo} class="logo-image" alt="santi_logo"></img>
      </div>
      <div class="middle-space"></div>
      <div class="navbar-links-container">
        <a
          href="https://www.linkedin.com/in/santiagoaday/"
          target="_blank"
          rel="noreferrer"
        >
          <Linkedin className="navbar-svg-hover" />
        </a>
        <a href="https://github.com/santiaday" target="_blank" rel="noreferrer">
          <Github className="navbar-svg-hover" />
        </a>
        <a
          href="https://santiagoaday.com/Santiago_Aday_Resume.pdf"
          target="_blank"
          rel="noreferrer"
        >
          <Resume className="navbar-svg-hover" />
        </a>
      </div>
    </div>
  );
};

export default Navbar;
