import React from "react";
import "./CardStyles.css";

const Navbar = ({ info, image }) => {
  return (
    <div class="card-container">
      <img src={image} className="aboutme-card-icon" />
      <div className="aboutme-card-content-container">
        <h1 className="aboutme-card-heading">{info.title}</h1>
        <p className="aboutme-card-explanation">{info.titleExplanation}</p>
        <h2 className="aboutme-card-skills-heading">Skills:</h2>
        {info.technologies.map((tech) => {
          return <p className="aboutme-card-skills-item">{tech}</p>;
        })}
      </div>
    </div>
  );
};

export default Navbar;
