import React from "react";
import "./SplashPage.css";
import SantiPic from "../../Resources/splashpage_image.png";
import OrangeObj from "../../Resources/orange_obj.png";
import BlueObj from "../../Resources/blue_obj.png";

const SplashPage = () => {
  return (
    <div class="splashpage-container">
      <h1 class="splashpage-heading">Hello, I'm Santi</h1>
      <h2 class="splashpage-subheading">
        I’m a full-stack software engineer and <br />
        operations manager
      </h2>
      <div className="splashpage-image-container">
        <div className="splashpage-orange-object-container">
          <img
            src={OrangeObj}
            alt="orange_obj"
            className="splashpage-orange-obj"
          />
        </div>
        <img src={SantiPic} className="splashpage-image" />
        <div className="splashpage-blue-object-container">
          <img src={BlueObj} alt="blue_obj" className="splashpage-blue-obj" />
        </div>
      </div>
    </div>
  );
};

export default SplashPage;
