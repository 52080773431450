import React from "react";
import { useInView } from "react-intersection-observer";
import "./ProjectStyles.css";

const Project = ({ info, img }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    rootMargin: "-150px 0px",
  });

  return (
    <div ref={ref} class={`${inView ? "project-container" : "hidden"}`}>
      <div className="project-text">
        <h1 className="project-heading">{info.projectTitle}</h1>
        <h2 className="project-subheading">{info.projectDesc}</h2>
      </div>

      <a href={info.link} target="_blank">
        <img src={img} alt={info.projectTitle} className="project-image" />
      </a>
    </div>
  );
};

export default Project;
