import React from "react";
import "./FooterStyles.css";
import SantiLogo from "../../Resources/santi_logo.png";
import { ReactComponent as LinkedinFooter } from "../../Resources/linkedin_footer.svg";
import { ReactComponent as GithubFooter } from "../../Resources/github_icon.svg";
import { ReactComponent as EmailFooter } from "../../Resources/email_icon.svg";
import { ReactComponent as FooterIcon } from "../../Resources/footer_icon.svg";

const Footer = () => {
  return (
    <div class="footer-container">
      <div class="footer-inner-container">
        <div class="footer-inner-inner-container">
          <div class="footer-logo-container">
            <img src={SantiLogo} alt="santi_logo" class="footer-logo" />
          </div>
          <div class="footer-links-container-outer">
            <div class="footer-links-container">
              <a
                href="https://www.linkedin.com/in/santiagoaday/"
                target="_blank"
                rel="noreferrer"
              >
                <div class="footer-link">
                  <LinkedinFooter />
                </div>
              </a>
              <a
                href="https://github.com/santiaday"
                target="_blank"
                rel="noreferrer"
              >
                <div class="footer-link">
                  <GithubFooter />
                </div>
              </a>
            </div>
          </div>

          <div>
            <FooterIcon />
          </div>
          <h3 class="footer-phrase">Live Long & Prosper</h3>
        </div>
      </div>
    </div>
  );
};

export default Footer;
